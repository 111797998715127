/**
 * @author johan.magnusson@svenskaspel.se (Johan Magnusson)
 */
import { isValidElementType } from 'react-is';
import PropTypes from 'prop-types';
import './../../stylesheets/paper.less';
import '../../../../elevation/assets/stylesheets/elevation.less';

/**
 * Paper act as a container that represent the first layer on the z axis from the bottom surface on the page.
 * Shadows provide important visual cues about objects’ depth and directional movement.
 * They are the only visual cue indicating the amount of separation between surfaces. An object’s elevation determines the appearance of its shadow.
 * Paper represents Svenska Spels first level of elevation.
 * If you are looking for more complex integration take a look at Card component which is kind of a big brother to paper.
 *
 */
const Paper = ({
  branding,
  Component,
  className,
  children,
  disabled,
  isFlat,
  isOutlined,
  ...props
}) => {
  const classNames = ['paper', `paper-${branding}`];

  if (isFlat) {
    classNames.push(`paper-flat`);
  } else {
    classNames.push('elevation-100');
  }

  if (className) {
    classNames.push(className);
  }

  if (disabled) {
    //classNames.push('paper-link-disabled');
  }

  if (isOutlined) {
    classNames.push(`paper-outlined`);
  }

  if (Component === 'a' || Component.displayName && Component.displayName === 'Link') {
    classNames.push('paper-link');
    classNames.push('elevation-auto-raise');
  }
  const handleClick = (e) => {
    // Prevent default if paper is disabled
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <Component aria-disabled={disabled} className={classNames.join(' ')} onClick={handleClick} {...props}>
      {children}
    </Component>
  );
};

Paper.propTypes = {
  /**
   * Type of node
   */
  Component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error(
        `Invalid prop 'Component' supplied to 'Paper': the prop is not a valid React component`
      );
    }
  },
  /**
   * Set branding
   */
  branding: PropTypes.oneOf(['sport', 'tur', 'neutral']),
  /**
   * Content
   */
  children: PropTypes.node,
  /**
   * Add custom class
   */
  className: PropTypes.string,
  /**
   * Use to disable clickable card.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * Use if no shadow is wanted
   * @default false
   */
  isFlat: PropTypes.bool,
  /**
   * Use to get an outline (defaults to false)
   */
  isOutlined: PropTypes.bool
};

Paper.defaultProps = {
  branding: 'neutral',
  Component: 'div',
  disabled: false,
  isFlat: false,
  isOutlined: false
};

export default Paper;
